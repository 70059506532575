import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './SendWhatsAppMessage.css';

function SendWhatsAppMessage() {
    const [message, setMessage] = useState("");

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSend = () => {
        const whatsappURL = `https://wa.me/31649226997?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <div className="SendWhatsAppMessage">
            <input
                type="text"
                placeholder="Typ hier uw bericht"
                value={message}
                onChange={handleChange}
                className="send-wapp-message-input"
            />
            <button onClick={handleSend} className="wapp-send-button">
                <FaWhatsapp /> Verstuur
            </button>
        </div>
    );
}

export default SendWhatsAppMessage;
