import React, { useState } from 'react';
import axios from 'axios';
import './VacatureToevoegen.css';
import {useNavigate} from "react-router-dom";

function VacatureToevoegen() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const navigate = useNavigate();
    const [vacancyData, setVacancyData] = useState({
        category: '',
        jobTitle: '',
        jobDescription: '',
        typeOfEmployment: '',
        requiredDegree: '',
        availableHours: [],
        location: '',
        salary: '',
        company: '',
        contactPerson: '',
        contactPersonPhoneNumber: '',
    });

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;

        if (name === 'availableHours') {
            let updatedHours = [...vacancyData.availableHours];

            if (type === 'checkbox') {
                if (checked) {
                    updatedHours.push(value);
                } else {
                    updatedHours = updatedHours.filter((hour) => hour !== value);
                }

                setVacancyData({
                    ...vacancyData,
                    [name]: updatedHours,
                });
            }
        } else {
            setVacancyData({
                ...vacancyData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Convert availableHours array to a comma-separated string
        const formattedVacancyData = {
            ...vacancyData,
            availableHours: vacancyData.availableHours.join(', '), // Convert array to string
        };

        try {
            console.log("Submitting vacancyData: ", formattedVacancyData);

            const response = await axios.post(`${API_BASE_URL}/vacancy/create`, formattedVacancyData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Vacancy data successfully submitted');
                setVacancyData({
                    category: '',
                    jobTitle: '',
                    jobDescription: '',
                    typeOfEmployment: '',
                    requiredDegree: '',
                    availableHours: [], // Reset to empty array
                    location: '',
                    salary: '',
                    company: '',
                    contactPerson: '',
                    contactPersonPhoneNumber: '',
                });
                navigate('/verzoek-succesvol-ingediend');
            } else {
                console.error('Failed to submit vacancy data', response.data);
            }
        } catch (error) {
            if (error.response) {
                console.error('Server responded with an error:', error.response.data);
            } else {
                console.error('An error occurred:', error.message);
            }
        }
    };

    // Define salary options based on job type, up to €120,000
    const salaryOptions = {
        'Full-time': [
            '€30,000 - €40,000 per jaar',
            '€40,000 - €50,000 per jaar',
            '€50,000 - €60,000 per jaar',
            '€60,000 - €70,000 per jaar',
            '€70,000 - €80,000 per jaar',
            '€80,000 - €90,000 per jaar',
            '€90,000 - €100,000 per jaar',
            '€100,000 - €110,000 per jaar',
            '€110,000 - €120,000 per jaar'
        ],
        'Part-time': [
            '€15,000 - €20,000 per jaar',
            '€20,000 - €25,000 per jaar',
            '€25,000 - €30,000 per jaar',
            '€30,000 - €35,000 per jaar',
            '€35,000 - €40,000 per jaar',
            '€40,000 - €50,000 per jaar',
            '€50,000 - €60,000 per jaar',
            '€60,000 - €70,000 per jaar',
            '€70,000 - €80,000 per jaar'

        ],
        'Internship': [
            '€250 - €500 per maand',
            '€500 - €750 per maand',
            '€750 - €1000 per maand',
            '€1,250 - €1,500 per maand',
            '€1,500 +'
        ],
        'Freelance': [
            '€25 - €50 per uur',
            '€50 - €100 per uur',
            '€100 - €150 per uur',
            '€150 - €200 per uur',
            '€200 - €250 per uur'
        ]
    };

    return (
        <div className="vacature-toevoegen">
            <h2>Vacature toevoegen</h2>
            <form onSubmit={handleSubmit} className="vacature-form">
                <div className="form-group">
                    <div className="form-group">
                        <label htmlFor="category">Categorie</label>
                        <select
                            id="category"
                            name="category"
                            value={vacancyData.category}
                            onChange={handleChange}
                            required
                        >
                            <option value="productionAndLogistics">Productie & Logistiek</option>
                            <option value="constructionInstallationRenovation">Bouw, Installatie & Renovatie</option>
                            <option value="Healthcare">Zorg</option>
                            <option value="itIct">IT & ICT</option>
                            <option value="Freelance">Freelance</option>

                        </select>
                    </div>
                    <label htmlFor="jobTitle">Functie Titel</label>
                    <input
                        type="text"
                        id="jobTitle"
                        name="jobTitle"
                        value={vacancyData.jobTitle}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="jobDescription">Functie omschrijving</label>
                    <textarea
                        id="jobDescription"
                        name="jobDescription"
                        value={vacancyData.jobDescription}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="location">Locatie</label>
                    <input
                        type="text"
                        id="location"
                        name="location"
                        value={vacancyData.location}
                        onChange={handleChange}
                        required
                    />
                </div>

                <br/>

                <div className="form-group">
                    <label htmlFor="typeOfEmployment">Type dienstverband</label>
                    <select
                        id="typeOfEmployment"
                        name="typeOfEmployment"
                        value={vacancyData.typeOfEmployment}
                        onChange={handleChange}
                        required
                    >
                        <option value="Full-time">Fulltime</option>
                        <option value="Part-time">Parttime</option>
                        <option value="Internship">Stage</option>
                        <option value="Freelance">Freelance</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="requiredDegree">Vereist diploma</label>
                    <select
                        id="requiredDegree"
                        name="requiredDegree"
                        value={vacancyData.requiredDegree}
                        onChange={handleChange}
                        required
                    >
                        <option value="noRequirement">Geen diploma vereist</option>
                        <option value="mbo">MBO Diploma</option>
                        <option value="hbo">HBO Diploma</option>
                        <option value="university">WO Diploma</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="availableHours">Werk beschikbaarheid</label>
                    <div className="availability-options">
                        <label className={`option ${vacancyData.availableHours.includes('weekend') ? 'selected' : ''}`}>
                            <input
                                type="checkbox"
                                id="weekend"
                                name="availableHours"
                                value="weekend"
                                checked={vacancyData.availableHours.includes('weekend')}
                                onChange={handleChange}
                            />
                            Weekend
                        </label>

                        <label
                            className={`option ${vacancyData.availableHours.includes('holidays') ? 'selected' : ''}`}>
                            <input
                                type="checkbox"
                                id="holidays"
                                name="availableHours"
                                value="holidays"
                                checked={vacancyData.availableHours.includes('holidays')}
                                onChange={handleChange}
                            />
                            Feestdagen
                        </label>

                        <label
                            className={`option ${vacancyData.availableHours.includes('partTime') ? 'selected' : ''}`}>
                            <input
                                type="checkbox"
                                id="partTime"
                                name="availableHours"
                                value="partTime"
                                checked={vacancyData.availableHours.includes('partTime')}
                                onChange={handleChange}
                            />
                            Parttime
                        </label>

                        <label
                            className={`option ${vacancyData.availableHours.includes('fullTime') ? 'selected' : ''}`}>
                            <input
                                type="checkbox"
                                id="fullTime"
                                name="availableHours"
                                value="fullTime"
                                checked={vacancyData.availableHours.includes('fullTime')}
                                onChange={handleChange}
                            />
                            Fulltime
                        </label>

                        <label
                            className={`option ${vacancyData.availableHours.includes('shiftWork') ? 'selected' : ''}`}>
                            <input
                                type="checkbox"
                                id="shiftWork"
                                name="availableHours"
                                value="shiftWork"
                                checked={vacancyData.availableHours.includes('shiftWork')}
                                onChange={handleChange}
                            />
                            Ploegendiensten
                        </label>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="salary">Salaris</label>
                    <select
                        id="salary"
                        name="salary"
                        value={vacancyData.salary}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Selecteer salaris</option>
                        {salaryOptions[vacancyData.typeOfEmployment]?.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <br/>
                <div className="form-group">
                    <label htmlFor="company">Bedrijf</label>
                    <input
                        type="text"
                        id="company"
                        name="company"
                        value={vacancyData.company}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="contactPerson">Contactpersoon</label>
                    <input
                        type="text"
                        id="contactPerson"
                        name="contactPerson"
                        value={vacancyData.contactPerson}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="contactPersonPhoneNumber">Telefoonnummer contactpersoon</label>
                    <input
                        type="text"
                        id="contactPersonPhoneNumber"
                        name="contactPersonPhoneNumber"
                        value={vacancyData.contactPersonPhoneNumber}
                        onChange={handleChange}
                        required
                    />
                </div>
                <br/>

                <button type="submit" className="submit-btn">Verzenden</button>
            </form>
        </div>
    );
}

export default VacatureToevoegen;