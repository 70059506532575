import React, { useState } from 'react';
import { FaFacebookMessenger } from 'react-icons/fa';
import './SendFacebookMessage.css';

function SendFacebookMessage() {
    const [message, setMessage] = useState("");

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSend = () => {
        const facebookURL = `https://m.me/?text=${encodeURIComponent(message)}`;
        window.open(facebookURL, '_blank');
    };

    return (
        <div className="SendFacebookMessage">
            <input
                type="text"
                placeholder="Typ hier uw bericht"
                value={message}
                onChange={handleChange}
                className="fb-message-input"
            />
            <button onClick={handleSend} className="fb-send-button">
                <FaFacebookMessenger /> Verstuur
            </button>
        </div>
    );
}

export default SendFacebookMessage;
