import React, { useState } from 'react';
import './Rondleiding.css';
import {Link, useNavigate} from "react-router-dom";

function Rondleiding() {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleRedirectToConsultancy = () => {
        navigate('/consultancy'); // Navigate to the '/consultancy' route
    };

    const steps = [
        {
            id: 1,
            title: 'Welkom bij onze rondleiding',
            content: (
                <div>
                    <h2>Wat kunt u hier verwachten?</h2>
                    <br/>
                    <p>
                        In deze rondleiding laten we u zien wat onze dienst allemaal te bieden heeft. We nemen u stap voor stap mee door de belangrijkste functies en mogelijkheden, zodat u een goed beeld krijgt van wat u kunt verwachten.
                    </p>
                </div>
            )
        },
        {
            id: 2,
            title: 'Onze functies in een notendop',
            content: (
                <div>
                    <h2>Verkennen</h2>
                    <br/>
                    <ul className="finder-container">
                        <li style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="explorer-full-explanation-link" to='/api'>API:</Link>
                            <span> Interface die communicatie tussen verschillende softwaresystemen mogelijk maakt.</span>
                        </li>
                        <br/>
                        <li style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="explorer-full-explanation-link" to='/web-app'>Web-App:</Link>
                            <span> Toepassing toegankelijk via een webbrowser.</span>
                        </li>
                        <br/>

                        <li style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="explorer-full-explanation-link" to='/consultancy'>Consultancy:</Link>
                            <span> Professionele adviesdiensten.</span>
                        </li>
                        <br/>
                        <li style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="explorer-full-explanation-link" to='/betaalplan'>Betaalplan</Link>
                            <span>Wij hebben een betaalplan dat bij jou past.</span>
                        </li>
                        <br/>
                        <li style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="explorer-full-explanation-link" to='/artificial-intelligence'>Artificial Intelligence</Link>
                            <span>Technologie die menselijke intelligentie nabootst.</span>
                        </li>
                        {/*<li style={{ display: 'flex', flexDirection: 'column' }}>*/}
                        {/*    <Link className="explorer-full-explanation-link">Startende ondernemers:</Link>*/}
                        {/*    <span>Start verstandig met Develop Bit. Speciaal pakket en ondersteuning voor de startende ondernemer. </span>*/}
                        {/*</li>*/}



                        <br/>

                        <li style={{ display: 'flex', flexDirection: 'column' }}>
                            <Link className="explorer-full-explanation-link" to='/begrippen-lijst'>TECH Woordenboek:</Link>
                            <span> Woordenlijst van technische termen.</span>
                        </li>
                    </ul>
                </div>
            )
        },
        {
            id: 3,
            title: 'Een laatste woord',
            content: (
                <div>
                    <h2>Blijf ontdekken of onderneem actie.</h2>
                    <br/>
                    <p>
                        Bedankt voor het volgen van onze rondleiding. We hopen dat u nu een goed beeld heeft van wat wij te bieden hebben. Ga gerust verder op verkenning en ontdek alle mogelijkheden die voor u klaarstaan.
                    </p>
                    <br/>
                </div>
            )
        }
        ];


    return (
        <div className="Rondleiding">
            <div className="tour-container">
            <header className="header">
                <h1>{steps[currentStep - 1].title}</h1>
            </header>
            <section className="content">
                {steps[currentStep - 1].content}
            </section>
            <footer className="rondleiding-footer">
                {currentStep > 1 && (
                    <button className="prev-button" onClick={handlePrevStep}>
                        Vorige
                    </button>
                )}
                {currentStep < steps.length && (
                    <button className="next-button" onClick={handleNextStep}>
                        Volgende
                    </button>
                )}
                {currentStep === steps.length && (
                    <button className="book-consultancy-button" onClick={handleRedirectToConsultancy}>
                            Consultancy
                    </button>
                )}
            </footer>
            </div>
        </div>
    );
}

export default Rondleiding;

//TODO                             <span>Kies uit verschillende betaalopties die zijn ontworpen om aan jouw specifieke behoeften te voldoen. Of je nu op zoek bent naar een vaste prijs voor elk project, maandelijkse betalingen per gebruiker, een consistent maandelijks plan, of de flexibiliteit van micro-transacties - wij hebben een betaalplan dat bij jou past.</span>