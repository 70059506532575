import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';

function DashboardVacatures({ onVacancyCountsChange }) {
    const [vacancies, setVacancies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const token = localStorage.getItem('token'); // Retrieve token from local storage

    useEffect(() => {
        const fetchVacancies = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${API_BASE_URL}/vacancy/allavailable`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const fetchedVacancies = response.data;
                    setVacancies(fetchedVacancies);

                    // Calculate totals
                    const totalVacs = fetchedVacancies.length;
                    const enabledVacs = fetchedVacancies.filter(vacancy => vacancy.enabled).length;
                    const disabledVacs = totalVacs - enabledVacs;

                    // Store in localStorage
                    localStorage.setItem('totalVacs', totalVacs);
                    localStorage.setItem('enabledVacs', enabledVacs);
                    localStorage.setItem('disabledVacs', disabledVacs);

                    // Optionally, notify the parent component of the changes
                    if (onVacancyCountsChange) {
                        onVacancyCountsChange(totalVacs, enabledVacs, disabledVacs);
                    }
                } else {
                    setError('Failed to fetch vacancies.');
                }
            } catch (err) {
                setError('An error occurred while fetching vacancies.');
            } finally {
                setLoading(false);
            }
        };

        fetchVacancies();
    }, [API_BASE_URL, token, onVacancyCountsChange]);

    // Convert boolean values to human-readable text
    const booleanToText = (value) => (value ? 'Yes' : 'No');

    const handleEnable = async (uuid) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/vacancy/enable/${uuid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                // Update local state
                setVacancies((prevVacancies) =>
                    prevVacancies.map((vacancy) =>
                        vacancy.uuid === uuid ? { ...vacancy, enabled: true } : vacancy
                    )
                );

                // Recalculate totals
                const totalVacs = vacancies.length;
                const enabledVacs = vacancies.filter(vacancy => vacancy.enabled).length + 1;
                const disabledVacs = totalVacs - enabledVacs;

                // Store updated values in localStorage
                localStorage.setItem('totalVacs', totalVacs);
                localStorage.setItem('enabledVacs', enabledVacs);
                localStorage.setItem('disabledVacs', disabledVacs);

                // Notify parent component if needed
                if (onVacancyCountsChange) {
                    onVacancyCountsChange(totalVacs, enabledVacs, disabledVacs);
                }
            } else {
                setError('Failed to enable vacancy.');
            }
        } catch (err) {
            setError('An error occurred while enabling the vacancy.');
        }
    };

    const handleDisable = async (uuid) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/vacancy/disable/${uuid}`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                // Update local state
                setVacancies((prevVacancies) =>
                    prevVacancies.map((vacancy) =>
                        vacancy.uuid === uuid ? { ...vacancy, enabled: false } : vacancy
                    )
                );

                // Recalculate totals
                const totalVacs = vacancies.length;
                const enabledVacs = vacancies.filter(vacancy => vacancy.enabled).length - 1;
                const disabledVacs = totalVacs - enabledVacs;

                // Store updated values in localStorage
                localStorage.setItem('totalVacs', totalVacs);
                localStorage.setItem('enabledVacs', enabledVacs);
                localStorage.setItem('disabledVacs', disabledVacs);

                // Notify parent component if needed
                if (onVacancyCountsChange) {
                    onVacancyCountsChange(totalVacs, enabledVacs, disabledVacs);
                }
            } else {
                setError('Failed to disable vacancy.');
            }
        } catch (err) {
            setError('An error occurred while disabling the vacancy.');
        }
    };

    const handleDelete = async (uuid) => {
        if (window.confirm('Are you sure you want to delete this vacancy?')) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/vacancy/${uuid}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    // Update local state
                    setVacancies((prevVacancies) =>
                        prevVacancies.filter((vacancy) => vacancy.uuid !== uuid)
                    );

                    // Recalculate totals
                    const totalVacs = vacancies.length - 1;
                    const enabledVacs = vacancies.filter(vacancy => vacancy.enabled).length;
                    const disabledVacs = totalVacs - enabledVacs;

                    // Store updated values in localStorage
                    localStorage.setItem('totalVacs', totalVacs);
                    localStorage.setItem('enabledVacs', enabledVacs);
                    localStorage.setItem('disabledVacs', disabledVacs);

                    // Notify parent component if needed
                    if (onVacancyCountsChange) {
                        onVacancyCountsChange(totalVacs, enabledVacs, disabledVacs);
                    }
                } else {
                    setError('Failed to delete vacancy.');
                }
            } catch (err) {
                setError('An error occurred while deleting the vacancy.');
            }
        }
    };

    return (
        <section className="vacatures-section">
            <h3>Vacature Management</h3>

            {loading && <p>Loading vacancies...</p>}
            {error && <p className="error">{error}</p>}

            {!loading && !error && (
                <table>
                    <thead>
                    <tr>
                        <th>Created At</th>
                        <th>Category</th>
                        <th>Job Title</th>
                        <th>Description</th>
                        <th>Location</th>
                        <th>Employment Type</th>
                        <th>Required Degree</th>
                        <th>Available Hours</th>
                        <th>Salary</th>
                        <th>Company</th>
                        <th>Contact Person</th>
                        <th>Contact Phone</th>
                        <th>Enabled</th>
                        <th>Actions</th> {/* New column for actions */}
                    </tr>
                    </thead>
                    <tbody>
                    {vacancies.length > 0 ? (
                        vacancies.map((vacancy) => (
                            <tr key={vacancy.uuid}>
                                <td>{new Date(vacancy.createdAt).toLocaleString()}</td>
                                <td>{vacancy.category}</td>
                                <td>{vacancy.jobTitle}</td>
                                <td>{vacancy.jobDescription}</td>
                                <td>{vacancy.location}</td>
                                <td>{vacancy.typeOfEmployment}</td>
                                <td>{vacancy.requiredDegree}</td>
                                <td>{vacancy.availableHours}</td>
                                <td>{vacancy.salary}</td>
                                <td>{vacancy.company}</td>
                                <td>{vacancy.contactPerson}</td>
                                <td>{vacancy.contactPersonPhoneNumber}</td>
                                <td>{booleanToText(vacancy.enabled)}</td>
                                <td>
                                    <button
                                        onClick={() =>
                                            vacancy.enabled
                                                ? handleDisable(vacancy.uuid)
                                                : handleEnable(vacancy.uuid)
                                        }
                                    >
                                        {vacancy.enabled ? 'Disable' : 'Enable'}
                                    </button>
                                    <button
                                        onClick={() => handleDelete(vacancy.uuid)}
                                        className="delete-button"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="11">No vacancies available</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            )}
        </section>
    );
}

export default DashboardVacatures;

