import React from 'react';
import './Personeel.css';
function Personeel() {
    return (
        <div className="Personeel">
            <p>Ok</p>
        </div>
    );
}

export default Personeel;
