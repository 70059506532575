import React from 'react';
import './SuccesfulInquiry.css';

function SuccesfulInquiry() {
    return (
        <div className="succesful-inquiry-container">
            <h1>Verzoek Succesvol Ingediend</h1>
            <p>Bedankt voor uw aanvraag! Uw vacature is succesvol ingediend en wacht op beoordeling door de beheerdster.</p>
            <p>U ontvangt binnenkort een bevestiging per e-mail.</p>
            <button
                className="back-to-home-btn"
                onClick={() => window.location.href = '/'} // Redirect to home
            >
                Terug naar Home
            </button>
        </div>
    );
}

export default SuccesfulInquiry;

