import React from 'react';
import './HomepageRouteToConsultancy.css';
import { useNavigate } from "react-router-dom";
import { BiEuro } from "react-icons/bi";
import {SiRazorpay} from "react-icons/si";
import {IoIosInformationCircleOutline} from "react-icons/io"; /* Import Euro icon from react-icons */

function HomepageRouteToConsultancy() {
    const navigate = useNavigate();

    function handleRedirectToConsultancy() {
        navigate('/consultancy');
    }

    return (
        <div className="HomepageRouteToConsultancy">
            <button onClick={handleRedirectToConsultancy} className="hp-route-consultancy">
                {/*<BiEuro className="hp-route-consultancy-icon" />*/}
                <IoIosInformationCircleOutline className="hp-route-consultancy-icon"/>
                Consultancy
            </button>
            <div className="hp-route-content-consultancy">
                <p className="hp-route-to-pricing">Een succesvol traject begint bij deskundige consultancy.</p>
            </div>

        </div>
    );
}

export default HomepageRouteToConsultancy;