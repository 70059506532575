import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TalentPool.css';

function TalentPool() {
    const navigate = useNavigate();

    const handleSignUpClick = () => {
        navigate('/talent-pool/inschrijven');
    };

    const handleExamineClick = () => {
        navigate('/talent-pool/bekijken');
    };

    return (
        <div className="TalentPool">
            <div className="talent-pool-container">
                <h1 className="talent-pool-title">Talent pool</h1>
                <h2 className="talent-pool-subtitle">Kies hoe u verder wilt gaan</h2>
                <button onClick={handleSignUpClick} className="talent-pool-sign-up">Inschrijven voor talent pool</button>
                <button onClick={handleExamineClick} className="talent-pool-examine">Als werkgever/recruiter</button>
            </div>
        </div>
    );
}

export default TalentPool;
