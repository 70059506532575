// src/components/DashboardSettings.js
import React from 'react';

function DashboardSettings() {
    return (
        <section className="dashboard-settings">
            <h3>Settings</h3>
            <p>Manage your dashboard settings here.</p>
        </section>
    );
}

export default DashboardSettings;
