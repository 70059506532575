import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './JobBoard.css';

function JobBoard() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [filter, setFilter] = useState("");
    const [sortCriteria, setSortCriteria] = useState("title");
    const [selectedJobType, setSelectedJobType] = useState("");

    useEffect(() => {
        const token = localStorage.getItem('token'); // Attempt to retrieve the token from localStorage

        const config = {
            headers: {
                'Content-Type': 'application/json', // Set Content-Type for all requests
            },
        };

        // If a token exists, add the Authorization header
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        // Fetch job data from backend API
        axios.get(`${API_BASE_URL}/vacancy/all`, config)
            .then(response => {
                // Map backend data to match the structure expected by the frontend
                const mappedJobs = response.data.map(job => ({
                    uuid: job.uuid,
                    createdAt: job.createdAt,
                    category: job.category,
                    jobTitle: job.jobTitle,
                    jobDescription: job.jobDescription,
                    location: job.location,
                    typeOfEmployment: job.typeOfEmployment,
                    requiredDegree: job.requiredDegree,
                    availableHours: job.availableHours,
                    salary: job.salary,
                    company: job.company,
                    contactPerson: job.contactPerson,
                    contactPersonPhoneNumber: job.contactPersonPhoneNumber,
                    paid: job.paid,
                    enabled: job.enabled,
                }));

                setJobs(mappedJobs); // Set jobs with mapped data from API
                setFilteredJobs(mappedJobs); // Initially, set filtered jobs to the full data set
            })
            .catch(error => {
                if (error.response) {
                    console.error('Error fetching job vacancies:', error.response.data); // Log error response data
                } else {
                    console.error('Error fetching job vacancies:', error);
                }
            });
    }, [API_BASE_URL]);

    useEffect(() => {
        filterAndSortJobs();
    }, [filter, sortCriteria, jobs, selectedJobType]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortCriteria(e.target.value);
    };

    const handleJobTypeChange = (e) => {
        setSelectedJobType(e.target.value);
    };

    const filterAndSortJobs = () => {
        // Ensure filter and sort criteria are valid
        const lowerCaseFilter = filter ? filter.toLowerCase() : "";

        let filtered = jobs.filter(job => {
            // Provide default empty strings if job properties are null or undefined
            const title = job.title ? job.title.toLowerCase() : "";
            const company = job.company ? job.company.toLowerCase() : "";
            const location = job.location ? job.location.toLowerCase() : "";

            return (
                (title.includes(lowerCaseFilter) ||
                    company.includes(lowerCaseFilter) ||
                    location.includes(lowerCaseFilter)) &&
                (selectedJobType === "" || job.jobType === selectedJobType)
            );
        });

        switch (sortCriteria) {
            case "title":
                filtered.sort((a, b) => (a.title || "").localeCompare(b.title || ""));
                break;
            case "company":
                filtered.sort((a, b) => (a.company || "").localeCompare(b.company || ""));
                break;
            case "location":
                filtered.sort((a, b) => (a.location || "").localeCompare(b.location || ""));
                break;
            case "salary":
                filtered.sort((a, b) => {
                    const salaryA = parseInt((a.salary || "").replace(/\D/g, ''), 10) || 0;
                    const salaryB = parseInt((b.salary || "").replace(/\D/g, ''), 10) || 0;
                    return salaryA - salaryB;
                });
                break;
            default:
                break;
        }

        setFilteredJobs(filtered);
    };


    const createWhatsAppLink = (jobTitle, company, location) => {
        const phoneNumber = '31649226997';  // WhatsApp number (without + sign)
        const message = `Hallo, ik ben geïnteresseerd in de functie ${jobTitle} bij ${company}, locatie: ${location}. Kunt u mij meer informatie geven?`;
        return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    };


    const createEmailLink = (jobTitle, company) => {
        const subject = `Application for ${jobTitle} at ${company}`;
        const body = `Dear Hiring Manager,%0D%0A%0D%0AI am interested in the ${jobTitle} position at ${company}. Please provide more information about the role.%0D%0A%0D%0AThank you.%0D%0A`;
        return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };

    return (
        <div className="job-board">
            <header className="job-board-header">
                <h1>Vacature Bank</h1>
            </header>
            {/*<div className="job-board-controls">*/}
            {/*    <div className="job-board-controls-filter-by-typing">*/}
            {/*        <input*/}
            {/*            className="type-into-filter-bar"*/}
            {/*            type="text"*/}
            {/*            placeholder="Typ en filter"*/}
            {/*            value={filter}*/}
            {/*            onChange={handleFilterChange}*/}
            {/*        />*/}
            {/*        <select className="select-filter-type-of-bar" value={sortCriteria} onChange={handleSortChange}>*/}
            {/*            <option value="title">Sorteren op Titel</option>*/}
            {/*            <option value="company">Sorteren op Bedrijf</option>*/}
            {/*            <option value="location">Sorteren op Locatie</option>*/}
            {/*            <option value="salary">Sorteren op Salaris</option>*/}
            {/*        </select>*/}
            {/*    </div>*/}
            {/*    <select value={selectedJobType} onChange={handleJobTypeChange}>*/}
            {/*        <option value="">Alle Type Dienstverband</option>*/}
            {/*        <option value="Full-time">Voltijds</option>*/}
            {/*        <option value="Part-time">Deeltijds</option>*/}
            {/*        <option value="Freelance">Freelance</option>*/}
            {/*        <option value="Internship">Stage</option>*/}
            {/*    </select>*/}
            {/*</div>*/}
            <main className="job-board-main">
                {filteredJobs.length > 0 ? (
                    filteredJobs.map(job => (
                        <div key={job.id} className="job-card">
                            <h2 className="job-title">{job.jobTitle}</h2>
                            <p className="job-description">{job.jobDescription}</p>
                            <p className="job-type"><strong>Hoofdkantoor in: </strong> {job.location}</p>
                            <p className="job-type"><strong>Type diensstverband:</strong> {job.typeOfEmployment}</p>
                            <p className="job-type"><strong>Diploma vereist:</strong> {job.requiredDegree}</p>
                            <p className="job-type"><strong>Werk beschikbaarheid:</strong> {job.availableHours}</p>

                            <p className="job-type"><strong>Salaris:</strong> {job.salary}</p>
                            <p className="job-type"> <strong>Vacature aanbieder: </strong> {job.company}</p>
                            {/*<p className="job-type"><strong>Contactpersoon:</strong> {job.contactPerson}</p>*/}
                            {/*<p className="job-type"><strong>Tel contact:</strong> {job.contactPersonPhoneNumber}</p>*/}

                            <div className="job-actions">
                                <a
                                    href={createWhatsAppLink(job.jobTitle, job.company, job.location)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="job-action-whatsapp-button">
                                    WhatsAppen
                                </a>
                                <a
                                    href={createEmailLink(job.jobTitle, job.company)}
                                    className="job-action-mail-button">
                                    Mailen
                                </a>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Geen vacatures beschikbaar</p>
                )}
            </main>
        </div>
    );
}

export default JobBoard;

