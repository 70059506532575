import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import DashboardVacatures from './DashboardVacatures'; // Import the DashboardVacatures component
import DashboardSettings from './DashboardSettings';

function Dashboard() {
    const [currentView, setCurrentView] = useState('Home');
    const [vacancyCounts, setVacancyCounts] = useState({
        total: 0,
        enabled: 0,
        disabled: 0
    });

    useEffect(() => {
        const isAuth = localStorage.getItem('isAuth');
        // Uncomment this to handle authentication
        // if (!isAuth) {
        //     window.location.href = '/login';
        // }

        // Load counts from localStorage
        const total = localStorage.getItem('totalVacs') || 0;
        const enabled = localStorage.getItem('enabledVacs') || 0;
        const disabled = localStorage.getItem('disabledVacs') || 0;

        setVacancyCounts({ total: Number(total), enabled: Number(enabled), disabled: Number(disabled) });
    }, []);

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    return (
        <div className="dashboard-container">
            <aside className="dashboard-sidebar">
                <nav>
                    <ul>
                        <li onClick={() => handleViewChange('Home')}>Home</li>
                        <li onClick={() => handleViewChange('Vacatures')}>Vacatures</li>
                        <li onClick={() => handleViewChange('Settings')}>Settings</li>
                    </ul>
                </nav>
            </aside>
            <main className="dashboard-main">
                <header className="dashboard-header">
                    <h1>{currentView}</h1>
                    <div className="dashboard-controls">
                        <button onClick={() => handleViewChange(currentView)}>Refresh</button>
                    </div>
                </header>

                {currentView === 'Home' && (
                    <>
                        <section className="dashboard-stats">
                            <div className="stat-card">
                                <h2>Total Users</h2>
                                <p>1,234</p>
                            </div>
                            <div className="stat-card">
                                <h2>Active Users</h2>
                                <p>567</p>
                            </div>
                            <div className="stat-card">
                                <h2>Revenue</h2>
                                <p>$12,345</p>
                            </div>
                        </section>

                        <section className="dashboard-charts">
                            <div className="chart">
                                <h3>User Growth</h3>
                                {/* Placeholder for User Growth Chart */}
                            </div>
                            <div className="chart">
                                <h3>Sales Report</h3>
                                {/* Placeholder for Sales Report Chart */}
                            </div>
                        </section>

                        <section className="dashboard-table">
                            <h3>Recent Activity</h3>
                            <table>
                                <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Action</th>
                                    <th>Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>John Doe</td>
                                    <td>Logged in</td>
                                    <td>2024-09-10</td>
                                </tr>
                                <tr>
                                    <td>Jane Smith</td>
                                    <td>Purchased Item</td>
                                    <td>2024-09-09</td>
                                </tr>
                                </tbody>
                            </table>
                        </section>
                    </>
                )}

                {currentView === 'Vacatures' && (
                    <>
                        <section className="dashboard-stats">
                            <div className="stat-card">
                                <h2>Totaal</h2>
                                <p>{vacancyCounts.total}</p>
                            </div>
                            <div className="stat-card">
                                <h2>Online</h2>
                                <p>{vacancyCounts.enabled}</p>
                            </div>
                            <div className="stat-card">
                                <h2>Offline</h2>
                                <p>{vacancyCounts.disabled}</p>
                            </div>
                        </section>

                        <section className="dashboard-charts">
                            <div className="chart">
                                <h3>Vacature Groei</h3>
                                {/* Placeholder for Vacancy Growth Chart */}
                            </div>
                            <div className="chart">
                                <h3>Sales Rapport</h3>
                                {/* Placeholder for Sales Report Chart */}
                            </div>
                        </section>

                        {/* Render DashboardVacatures component with a callback for updating counts */}
                        <DashboardVacatures />
                    </>
                )}

                {currentView === 'Settings' && <DashboardSettings />} {/* Render DashboardSettings component */}
            </main>
        </div>
    );
}

export default Dashboard;
