import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import './StartSollicitatie.css';

function StartSollicitatie() {
    const navigate = useNavigate();
    const handleRedirectStartSollicitation = () => {
        navigate('/start-sollicitatie');
    };

    return (
        <section className="start-sollicitatie-section">
            <div className="sollicitatie-content">
                <p className="intro-text">Werken Nederland maakt het makkelijk om stappen vooruit te zetten.</p>
                <br/>
                <button onClick={handleRedirectStartSollicitation} className="start-sollicitatie-button">
                    Solliciteren&nbsp;<FaWhatsapp fontSize="1.2rem" />
                </button>
                <p className="contact-text">Neem direct contact op om jouw talenten te bespreken!</p>
            </div>
        </section>
    );
}

export default StartSollicitatie;
