import React, { useState } from 'react';
import './RequestCallAppointment.css';

function RequestCallAppointment() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const appointmentDetails = `Name: ${name}\nPhone: ${phone}\nMessage: ${message}`;
        alert(`Request submitted:\n${appointmentDetails}`);
        // Here you can add logic to send the request to a backend or email
    };

    return (
        <form className="RequestCallAppointment" onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Uw naam"
                value={name}
                onChange={handleNameChange}
                className="name-input"
            />
            <input
                type="tel"
                placeholder="Uw telefoonnummer "
                value={phone}
                onChange={handlePhoneChange}
                className="phone-input"
            />
            <textarea
                placeholder="Message"
                value={message}
                onChange={handleMessageChange}
                className="call-message-input"
            />
            <button type="submit" className="request-call-submit-button">
                Telefonisch contact
            </button>
        </form>
    );
}

export default RequestCallAppointment;
