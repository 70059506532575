import React, { useEffect, useState } from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import './Header.css';
// import Logo from '../assets/Logo.png';
import Werken from '../assets/Werken.png';
import {IoEnterOutline} from "react-icons/io5";
import {FaBars, FaTimes} from "react-icons/fa";


function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleLinkClick = (id) => {
        closeMenu();
    };

    function handleRedirectRondleiding() {
        closeMenu();
        navigate('/start-sollicitatie');
    }

    return (
        <header className="m-header">

            <Link to="/" id="hero-section" className="title-and-menu-link">
                <img src={Werken} alt="Werken Nederland" className="header-logo" onClick={handleLinkClick}/>
                {/*<h1>werkennederland.nl</h1>*/}
            </Link>

            <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                {isMenuOpen ? (
                    <FaTimes size="2rem" color="#fff" />  // Close icon when the menu is open
                ) : (
                    <FaBars size="2rem" color="#fff" />  // Hamburger icon when the menu is closed
                )}
            </div>

            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <div className="play-nav-open-menu">

                {/*<ul>*/}
                {/*    /!*<li>*!/*/}
                {/*    /!*    <Link to="/begrippen-lijst"*!/*/}
                {/*    /!*          className={`nav-link ${location.pathname === '/begrippen-lijst' ? 'active' : ''}`}*!/*/}
                {/*    /!*          onClick={() => handleLinkClick('')}>*!/*/}
                {/*    /!*        Begrippenlijst*!/*/}
                {/*    /!*    </Link>*!/*/}
                {/*    /!*</li>*!/*/}
                {/*    <li>*/}
                {/*        <Link to="/talent-pool"*/}
                {/*              className={`nav-link ${location.pathname === '/talent-pool' ? 'active' : ''}`}*/}
                {/*              onClick={() => handleLinkClick('')}>*/}
                {/*            Talent pool*/}
                {/*        </Link>*/}
                {/*    </li>*/}
                {/*</ul>*/}
                <ul>
                    <li>
                        <Link to="/vacature-bank"
                              className={`nav-link ${location.pathname === '/vacature-bank' ? 'active' : ''}`}
                              onClick={() => handleLinkClick('')}>
                            Vacature bank
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to="/vacature-toevoegen"*/}
                    {/*          className={`nav-link ${location.pathname === '/vacature-toevoegen' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Vacature toevoegen*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <Link to="/open-sollicitatie"*/}
                    {/*          className={`nav-link ${location.pathname === '/open-sollicitatie' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Open sollicitatie*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    {/*<li>*/}
                    {/*    <Link to="/personeel"*/}
                    {/*          className={`nav-link ${location.pathname === '/personeel' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Personeel*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="/werk"*/}
                    {/*          className={`nav-link ${location.pathname === '/werk' ? 'active' : ''}`}*/}
                    {/*          onClick={() => handleLinkClick('')}>*/}
                    {/*        Werk*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
                <ul>
                    <li>
                        <button className="start-sollicitatie-header-button" onClick={handleRedirectRondleiding}>
                            Start sollicitatie{/*<IoEnterOutline className="rondleiding-icon"/>*/}
                        </button>
                    </li>
                </ul>
                {/*<ul>*/}
                {/*    <li>*/}
                {/*        <button>*/}
                {/*        <Link to="/login"*/}
                {/*              className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`}*/}
                {/*              onClick={() => handleLinkClick('')}>*/}
                {/*            Inloggen*/}
                {/*        </Link>*/}
                {/*        </button>*/}

                {/*    </li>*/}
                {/*</ul>*/}
                </div>

            </nav>

        </header>
    );


}

export default Header;
