import React from 'react';
import './Werk.css';

function Werk() {
    return (
        <div className="Werk">
            {/* Your application code here */}
        </div>
    );
}

export default Werk;
