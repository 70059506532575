import React from 'react';
import './TalentPoolSignUp.css';

function TalentPoolSignUp() {
    return (
        <div className="TalentPoolSignUp">
            <div className="talent-pool-category-sign-up-container">
                <h3 className="talent-pool-category-sign-up-subtitle-">Kies een categorie</h3>
                <ul className="talent-pool-category-sign-up-list">
                    <li className="talent-pool-category-sign-up-list-item">
                        Bouw
                    </li>
                    <li className="talent-pool-category-sign-up-list-item">
                        Zorg
                    </li>
                    <li className="talent-pool-category-sign-up-list-item">
                        ICT
                    </li>
                    <li className="talent-pool-category-sign-up-list-item">
                        Productie en Logistiek
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default TalentPoolSignUp;
