import React, { useState } from 'react';
import './BegrippenLijst.css';
import { Link } from 'react-router-dom'; // Import the corresponding CSS file

function BegrippenLijst() {
    const [searchQuery, setSearchQuery] = useState('');

    // Example concept articles (you can expand this with more data)
    const conceptArticles = [
        {
            id: 1,
            title: 'Web-Applicatie',
            description: 'Een webapplicatie is een applicatie die via een webbrowser kan worden gebruikt. Het biedt gebruikersfunctionaliteit vergelijkbaar met traditionele desktopapplicaties, maar vereist geen installatie op het lokale apparaat.',
            link: '/uitgelegd/web-app'
        },
        {
            id: 2,
            title: 'API',
            description: 'Een API (Application Programming Interface) definieert de manier waarop softwarecomponenten met elkaar kunnen communiceren. Het maakt het mogelijk om functionaliteit en data van een applicatie beschikbaar te stellen voor andere softwareprogramma\'s.',
            link: '/uitgelegd/api'
        },
        {
            id: 3,
            title: 'Artificial Intelligence',
            description: 'Artificial Intelligence (AI) verwijst naar de simulatie van menselijke intelligentie in machines die geprogrammeerd zijn om te denken en te leren. AI-toepassingen omvatten machine learning, natuurlijke taalverwerking en robotica, onder andere.',
            link: '/uitgelegd/artificial-intelligence'
        },
        {
            id: 4,
            title: 'Full-Stack Development',
            description: 'Full-stack development verwijst naar de ontwikkeling van zowel de front-end als de back-end van een webapplicatie. Een full-stack developer heeft kennis van zowel client-side technologieën zoals HTML, CSS en JavaScript, als server-side technologieën zoals Java, Python, Node.js en databases.',
            link: '/uitgelegd/full-stack-development'
        },
        {
            id: 5,
            title: 'Front-End Development',
            description: 'Front-end development richt zich op het bouwen van de gebruikersinterface en gebruikerservaring van een webapplicatie. Het omvat het gebruik van HTML, CSS en JavaScript om interactieve en visueel aantrekkelijke webpagina\'s te creëren die gebruikvriendelijk zijn en in verschillende browsers en apparaten werken.',
            link: '/uitgelegd/front-end-development'
        },
        {
            id: 6,
            title: 'Back-End Development',
            description: 'Back-end development verwijst naar de serverzijde van een webapplicatie. Het omvat de ontwikkeling en beheer van de server, database en applicatielogica die de front-end ondersteunt. Populaire back-end technologieën zijn onder andere Java, Python, Ruby, en Node.js.',
            link: '/uitgelegd/back-end-development'
        },
        {
            id: 7,
            title: 'DevOps',
            description: 'DevOps is een set van praktijken die softwareontwikkeling (Dev) en IT-operaties (Ops) combineert. Het doel is om de ontwikkelingscyclus van applicaties te verkorten en continue levering en kwaliteit te bieden door middel van automatisering, samenwerking en integratie.',
            link: '/uitgelegd/devops'
        },
        {
            id: 8,
            title: 'Cloud Computing',
            description: 'Cloud computing verwijst naar het leveren van verschillende diensten via het internet, zoals opslag, servers, databases, netwerken en software. Het biedt flexibiliteit, schaalbaarheid en kostenefficiëntie voor bedrijven.',
            link: '/uitgelegd/cloud-computing'
        },
        {
            id: 9,
            title: 'Cybersecurity',
            description: 'Cybersecurity omvat de praktijk van het beschermen van systemen, netwerken en programma\'s tegen digitale aanvallen. Het richt zich op het voorkomen van ongeautoriseerde toegang, cyberaanvallen en datalekken.',
            link: '/uitgelegd/cybersecurity'
        },
        {
            id: 10,
            title: 'Data Science',
            description: 'Data science is een interdisciplinair veld dat gebruikmaakt van statistische en computationele technieken om kennis en inzichten uit gestructureerde en ongestructureerde data te extraheren.',
            link: '/uitgelegd/data-science'
        },
        {
            id: 11,
            title: 'Machine Learning',
            description: 'Machine learning is een subset van kunstmatige intelligentie waarbij computers algoritmen gebruiken om patronen te leren en voorspellingen te doen op basis van data. Het wordt gebruikt in toepassingen zoals spraakherkenning, beeldherkenning en aanbevelingssystemen.',
            link: '/uitgelegd/machine-learning'
        }
    ];



    // Filter function that prioritizes title matches first
    const filterArticles = () => {
        if (searchQuery.trim() === '') {
            return conceptArticles; // Return all articles if search query is empty
        }

        const lowercaseQuery = searchQuery.toLowerCase();
        const titleMatches = conceptArticles.filter(article =>
            article.title.toLowerCase().includes(lowercaseQuery)
        );
        const descriptionMatches = conceptArticles.filter(article =>
            article.description.toLowerCase().includes(lowercaseQuery) &&
            !titleMatches.includes(article) // Exclude articles already in titleMatches
        );

        return [...titleMatches, ...descriptionMatches];
    };

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className="BegrippenLijst">
            <h2 className="begrippen-lijst-title">Begrippen Lijst</h2>
            <div className="search-container">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Zoek begrippen..."
                    value={searchQuery}
                    onChange={handleInputChange}
                />
            </div>
            {filterArticles().map(article => (
                <div className="concept-article" key={article.id}>
                    <legend className="concept-title">{article.title}</legend>
                    <p className="concept-description">{article.description}</p>
                    {/*<Link to={article.link} className="full-explanation-link">*/}
                    {/*    Lees hier alles over {article.title.toLowerCase()}*/}
                    {/*</Link>*/}
                </div>
            ))}
        </div>
    );
}

export default BegrippenLijst;

