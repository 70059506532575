// HeroSection.js

import React from 'react';
import './HeroSection.css';
import {useNavigate} from "react-router-dom";
import {IoEnterOutline} from "react-icons/io5";
import {FaWhatsapp} from "react-icons/fa";

const HeroSection = () => {
    const navigate = useNavigate();

    const handleRedirectVacatureBank = () => {
        navigate('/vacature-bank');
    };

    return (
        <div className="hero-section">
            <h1>Werken Nederland</h1>
            <p>
                De arbeidsbemidelaar in Nederland
            </p>
            <button onClick={handleRedirectVacatureBank} className="rondleiding-button">
                🚀  Vacaturebank {/*insert space*/}
            </button>
        </div>
    );
};

export default HeroSection;
