import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Corrected import

export const AuthContext = createContext({});

function AuthContextProvider({ children }) {
    const [isAuth, toggleIsAuth] = useState({
        isAuth: false,
        user: null,
        status: 'pending',
    });

    const [adminSelect, setAdminSelect] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decoded = jwtDecode(token); // Use jwtDecode instead of jwt_decode
                if (decoded.exp * 1000 < Date.now()) {
                    isNotAuth();
                } else {
                    toggleIsAuth({
                        isAuth: true,
                        user: decoded.user,
                        status: 'done',
                    });
                }
            } catch (e) {
                isNotAuth();
            }
        } else {
            toggleIsAuth({
                isAuth: false,
                user: null,
                status: 'done',
            });
        }
    }, []);

    function login(JWT) {
        localStorage.setItem('token', JWT);
        const decoded = jwtDecode(JWT); // Use jwtDecode instead of jwt_decode

        toggleIsAuth({
            isAuth: true,
            user: decoded.user,
            status: 'done',
        });
    }

    function isNotAuth() {
        localStorage.clear();
        toggleIsAuth({
            isAuth: false,
            user: null,
            status: 'done',
        });

        navigate('/login');
    }

    const contextData = {
        ...isAuth,
        login,
        isNotAuth,
        adminSelect,
        setAdminSelect,
    };

    return (
        <AuthContext.Provider value={contextData}>
            {isAuth.status === 'done' ? children : <p>Loading...</p>}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
