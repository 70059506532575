import React from 'react';
import './TalentPoolExamine.css';

function TalentPoolExamine() {
    return (
        <div className="TalentPoolExamine">
            <div className="talent-pool-category-examine-container">
                <h3 className="talent-pool-category-examine-subtitle-">Kies een categorie</h3>
                <ul className="talent-pool-category-examine-list">
                    <li className="talent-pool-category-examine-list-item">
                        Bouw
                    </li>
                    <li className="talent-pool-category-examine-list-item">
                        Zorg
                    </li>
                    <li className="talent-pool-category-examine-list-item">
                        ICT
                    </li>
                    <li className="talent-pool-category-examine-list-item">
                        Productie en Logistiek
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default TalentPoolExamine;
