import React from 'react';
import './Homepage.css';
import HeroSection from "./HeroSection";
import Packages from "./Packages";
import WhyChooseUs from "./WhyChooseUs";
import HomepageRouteToPricing from "./HomepageRouteToPricing";
import ActionRouteToConsultancy from "./ActionRouteToConsultancy";
import HomepageRouteToConsultancy from "./HomepageRouteToConsultancy";
import WorkCategories from "./WorkCategories";
import StartSollicitatie from "./StartSollicitatie";

function Homepage() {
    return (
        <div className="homepage-container">
            <HeroSection />
            <br/>
            <StartSollicitatie />
            <br/>
            <WorkCategories />
            {/*<WhyChooseUs />*/}
            {/*<HomepageRouteToConsultancy />*/}
            {/*<HomepageRouteToPricing />*/}
            {/*<Packages />*/}

        </div>
    );
}

export default Homepage;
